import { GetStaticProps, GetStaticPaths } from 'next';
import type { NextPage } from 'next';
import logger from 'helpers/logger';
import { getPageContent } from 'application/repositories/pageContentRepository';
import { PageTypeFeature } from 'features/Modules/PageType/PageTypeFeature';
import { LayoutFeature } from 'features/Layouts/Layout/LayoutFeature';
import { getDictionaryItems } from 'application/repositories/dictionaryRepository';
import { getNavigationData } from 'application/repositories/navigationRepository';
import { getSite } from 'application/repositories/siteRepository';
import Application from 'application/entities/Application';
import { PageProps } from 'domain/page/pageProps';

const Page: NextPage<PageProps> = (pageProps: PageProps) => {
	logger.info('Rendering with ISR (incremental static regeneration)');

	const { content } = pageProps ?? {};
	const { page } = content ?? {};

	return (
		<LayoutFeature {...pageProps}>
			<PageTypeFeature {...page} />
		</LayoutFeature>
	);
};

export const getStaticPaths: GetStaticPaths = async () => {
	return {
		paths: [],
		// In the current setup fallback MUST be blocking
		// If fallback is set to true next will try to return the
		// page with an initially empty data-set, so that you can show
		// a loading page or do other operations until data is available
		// https://nextjs.org/docs/api-reference/data-fetching/get-static-paths#fallback-blocking
		fallback: 'blocking',
	};
};

export const getStaticProps: GetStaticProps<PageProps> = async (context) => {
	const { params, locale, preview, previewData: untypedPreviewData } = context;
	const { origin, slug } = params;

	const slugArr = slug as string[];
	const prefetch = slugArr?.includes('_sites');
	let path = '';

	if (!prefetch) {
		path = slug ? slugArr.join('/') : '';
	} else {
		path = slug ? slugArr.slice(3).join('/') : '';
	}

	const previewData = untypedPreviewData as Content.PreviewData;

	// Origin is base 64 encoded on the server filesystem (foldername) so we need to decode it
	Application.setUrl(atob(origin as string));
	locale ? Application.setLocale(locale) : null;
	// The Application Entity contains functions as well as data so
	// to clean it and make it serializable we stringify and parse it
	const serializedApplication = JSON.parse(JSON.stringify(Application));
	if (locale) {
		serializedApplication.locale = Application.locale.toString();
	}

	const profiler = logger.startTimer();

	try {
		const dictionaryPromise = getDictionaryItems({ preview, previewData });
		const navigationPromise = getNavigationData({ preview, previewData });
		const sitePromise = getSite({ preview, previewData });
		const contentPromise = getPageContent({
			path,
			preview,
			previewData,
		});

		return {
			props: {
				content: await contentPromise,
				navigation: await navigationPromise,
				dictionary: await dictionaryPromise,
				site: await sitePromise,
				application: serializedApplication,
			},
			revalidate: 1,
		};
	} catch (error) {
		const { statusCode } = error;

		if (statusCode === 404) {
			return {
				notFound: true,
				revalidate: 15,
			};
		}
		if (statusCode === 301 || statusCode === 302 || statusCode === 307 || statusCode === 308) {
			return {
				redirect: {
					destination: error.url,
					permanent: statusCode === 301 || statusCode === 308,
				},
			};
		}
		logger.error(`[${statusCode}] getStaticProps() \nCannot get static props \n${error}`);
		throw error;
	} finally {
		profiler.done({ message: 'getStaticProps', level: 'debug' });
	}
};

export default Page;
